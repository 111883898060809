<div class="content-container">
  <div class="content-header">
    <img src="../../assets/favicon.png">
    <h1 class="medium-title">SeniorContact - Bêta test setup guide</h1>
    <p class="subtitle-huge subtitle">Bonjour et merci de contribuer au test privé de SeniorContact !
      L'objectif est de faire tester l'appplication à un groupe varié de personnes, afin de dénicher des bugs, erreurs ou de changer certaines choses avant la sortie officielle.
    </p>
  </div>

  <div class="box small-margin-top">
    <b class="big-b">INSTALLATION DE L'APPLICATION SUR IOS</b>
    <div class="small-separator"></div>
    <ul class="small-margin-top list-number">
      <li class="paragraph">Installez l'application TestFlight sur l'AppStore : <a href="https://apps.apple.com/ch/app/testflight/id899247664?l=fr-FR">https://apps.apple.com/ch/app/testflight/id899247664?l=fr-FR</a>. Cet application permet l'installation de SeniorContact. Il faudra aussi passer par TestFlight si une mise à jour est disponible.</li>
      <li class="paragraph">Dans le mail d'invitation envoyé par Apple, cliquez sur le bouton "<b>Voir dans TestFlight</b>" et copiez le code d'invitation.</li>
      <li class="paragraph">Allez dans l'application TestFlight, cliquez sur le bouton en haut à droite "<b>Redeem</b>"" et collez le code d'invitation copié.</li>
      <li class="paragraph">Une fois l'application installée, rejoignez-la et entrez vos identifiant de connection qui vous ont été envoyés par WhatsApp ou bien Email.</li>
    </ul>
  </div>

  <div class="box small-margin-top">
    <b class="big-b">STRUCTURE DE L'APPLICATION</b>
    <div class="small-separator"></div>
    <p class="paragraph very-small-margin-top">La structure de l'application est plutot simple. La page principale est la conversation avec votre Senior, mais en cliquant sur la flêche (<img src="../../assets/icons/chevron-left.svg" class="icon">) en haut à gauche, vous pouvez accèder au menu et ainsi changer de page.</p>
    <ul class="small-margin-top list-bullets">
      <li class="paragraph">Dans la page "<b>Conversation</b>" vous pourrez envoyer des messages texte, audio, images ou bien vidéos.</li>
      <li class="paragraph">Dans la page "<b>Abonnement</b>" vous retrouverez tous les membres présents dans votre abonnement. Si vous êtes administrateur, vous pourrez changer le profil du senior, ajouter des membres et modifier les permissions des membres actuels.</li>
      <li class="paragraph">Dans la page "<b>Rappels</b>" vous pourrez créer des rappels pour votre senior. Un rappel est un message envoyé automatiquement à la fréquence souhaitée. Si vous êtes administrateur, vous pouvez permettre aux membre de votre abonnement d'ajouter leurs propres rappels si vous cocher la permission dans la page "<b>Abonnement</b>".</li>
      <li class="paragraph">Dans la page "<b>Appareil</b>", uniquement accessible si vous êtes l'administrateur du senior, vous pourrez régler toutes les fonctionnalités reliées à sa tablette. Comme le diaporama, les permissions et pleins d'autres choses.</li>
      <li class="paragraph">Dans la page "<b>Réglages</b>", accessible en cliquant sur le bouton (<img src="../../assets/icons/cog-outline-orange.svg" class="icon">) présent en bas à droite du menu, vous permettera de gérer toutes les choses classiques reliées à votre compte.</li>
    </ul>
  </div>

  <div class="box small-margin-top">
    <b class="big-b">FONCTIONNALITÉS MANQUANTES</b>
    <div class="small-separator"></div>
    <p class="paragraph very-small-margin-top">Il est important de noter que l’application n’est pas terminée, et manque certaines fonctionnalités importante, dont voici une liste exhaustive :
    </p>
    <ul class="small-margin-top list-bullets">
      <li class="paragraph">L’appel vidéo entre les membres et senior.</li>
      <li class="paragraph">Plus de permissions pour le senior, par exemple la possibilité qu'il envoie des images, vidéos ou bien messages textes (uniquement possible si l'administrateur l'accepte).</li>
      <li class="paragraph">La mise en place du système d'abonnement avec Apple & Android, changement d'abonnement et restriction des fonctionnalités en fonction de l'abonnement selectionné.</li>
      <li class="paragraph">Le système de création de compte et d'abonnement présent dès le téléchargement de l'application. Un sorte de "step by step" permettant de créer & rejoindre un abonnement et setup un appareil de senior simplement et rapidement.</li>
      <li class="paragraph">L'envoi d'emails lors d'un changement de mot de passe, création de compte, etc..</li>
      <li class="paragraph">Ainsi que pleins d'autres bugs ou problèmes à résoudre & changement apportés lors du bêta test.</li>
    </ul>
  </div>

  <div class="box small-margin-top margin-bottom">
    <b class="big-b">CONFIGURATION DE LA TABLETTE (admin uniquement)</b>
    <div class="small-separator"></div>
    <p class="paragraph very-small-margin-top">Pour tous les administrateurs (une personne dédiée pour s'occuper de la configuration de la tablette pour le senior), cet étape est <b>obligatoire</b>.<br>
      Une fois connecté sur l'application, vous devriez être sur la page de conversation avec le senior.
    </p>
    <ul class="small-margin-top list-number">
      <li class="paragraph">Cliquez sur la flêche (<img src="../../assets/icons/chevron-left.svg" class="icon">) présente en haut à gauche de l'application, et rejoignez la page "<b>Appareil</b>". Une fois dessus, cliquez sur le bouton "<b>Relier un appareil</b>".</li>
      <li class="paragraph">Répétez l'étape 1, 2 et 3 de l'installation de l'application (tout en haut de la page)<b> sur la tablette du senior</b>. Une fois arrivé sur la page de login, cliquez sur le bouton "<b>Associer cet appareil à un senior</b>". Un QR code apparaitra.</li>
      <li class="paragraph">Scannez le QR code avec votre application sur votre appareil en cliquant sur le bouton <b>"Scanner le QR code"</b> (depuis l'application, pas depuis la camera du téléphone).</li>
    </ul>
    <p class="paragraph very-small-margin-top">La liaison de la tablette du senior devrait s'effectuer en quelques secondes.<br>
      Une fois la liaison effectuée, vous aurrez accès à toutes les pages de configuration des fonctionnalités de la tablette du senior. Vous pourrez alors ajouter des images dans le diaporama, changer les permissions du senior, et pleins d'autres choses !
    </p>
  </div>
</div>
